<template>
  <v-dialog
    v-model="isActive"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Compose mass email</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div
                class="bulktabSwitcher"
              >
                <div class="tabSwitcherInnerWRapper">
                  <div
                    :class="selectedInputType === 'manual' ? 'tab selected' : 'tab'"
                    @click="_selectInputType('manual')"
                  >
                    Manual Input
                  </div>
                  <div
                    :class="selectedInputType === 'sendgrid' ? 'tab selected' : 'tab'"
                    @click="_selectInputType('sendgrid')"
                  >
                    Use sendgrid template
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="selectedInputType === 'manual'">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="field.subject"
                label="Subject *"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="field.message"
                label="Message *"
                required
                counter
                maxlength="200"
                full-width
                single-line
              />
            </v-col>
          </v-row>
          <small>* indicates required field</small>
        </v-container>
        <v-container
          v-if="selectedInputType === 'sendgrid'"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="sendgridTemplateId"
                label="Sendgrid template id *"
              />
            </v-col>
          </v-row>
          <small>* indicates required field</small>
        </v-container>

        <v-snackbar v-model="snackbar">
          {{ notification }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="send"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import AuthService from '@/services/AuthService'
  const auth = new AuthService()

  export default {
    name: 'GroupMassEmailComposer',
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
      groupId: {
        type: Number,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      user: null,
      dialog: false,
      selectedInputType: 'manual',
      groups: [],
      field: {
        name: '',
        message: '',
        subject: '',
        mediaUrl: '',
        group: '',
      },
      sendgridTemplateId: '',
      snackbar: false,
      notification: 'Unknown',
    }),

    watch: {
      isActive (val) {
        console.log('isActive', val)
        val || this.close()
      },
    },

    async created () {
      this.user = await auth.getUser()
      this.usremail = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      async initialize () {
      },

      close () {
        //   reset and close
        this.field = {
          name: '',
          message: '',
          subject: '',
          mediaUrl: '',
          group: '',
        }
        this.sendgridTemplateId = ''
        this.$emit('on-dismiss')
      },

      async send () {
        // First validate the form
        const isValid = this._checkIfFormIsValid()
        console.log('isValid :>> ', isValid)

        if (!isValid) {
          return
        }

        // Prepare payload
        const payload = this._preparePayload()
        console.log('payload :>> ', payload)
        try {
          await this.httpConnector.makeRequest(
            'post',
            `${this.apiEndpoint}email/masssend?code=${this.apiCode}`,
            payload,
          )

          this.$emit('on-success')
          this.close()
        } catch (error) {

        }
      },

      _checkIfFormIsValid () {
        if (this.selectedInputType === 'manual') {
          if (!this.field.subject.length || !this.field.message.length) {
            // Show notification
            this.notification = 'Please fill all the required fields'
            this.snackbar = true
            return false
          }
        } else if (this.selectedInputType === 'sendgrid') {
          if (!this.sendgridTemplateId.length) {
            // Show notification
            this.notification = 'Please fill all the required fields'
            this.snackbar = true
            return false
          }
        }
        return true
      },

      _preparePayload () {
        if (this.selectedInputType === 'manual') {
          return {
            Subject: this.field.subject,
            Content: this.field.message,
            GroupID: this.groupId,
          }
        } else if (this.selectedInputType === 'sendgrid') {
          return {
            SendGridTemplateID: this.sendgridTemplateId,
            GroupID: this.groupId,
          }
        }
      },

      _selectInputType (type) {
        this.selectedInputType = type
      },
    },
  }
</script>
<style lang="scss" scoped>
.bulktabSwitcher {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  justify-content: flex-start;
  align-items: center;
  .tabSwitcherInnerWRapper {
    padding: 5px 8px;
    border: 1px solid #050505;
    border-radius: 25px;
    display: flex;
    .tab {
      padding: 5px 15px;
      font-size: 10px;
      cursor: pointer;
      color: #161616;
      font-weight: 600;
      &.selected {
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
        color: white;
        background-color: #1d1d1d;
        cursor: unset;
        border-radius: 25px;
      }
    }
  }
}
</style>
